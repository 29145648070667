import { useEffect, useRef, useState } from 'react'

// this hook helps for setting some css attributes only after a hide transition, e.g. visibility:hidden after the hide animation finished so elements are not tabbable
export const useHideDelay = (show: boolean, hideDelay: number) => {
    const [state, setState] = useState<'hidden' | 'shown' | 'hidingDelay'>(show ? 'shown' : 'hidden')
    const stateRef = useRef(state)
    stateRef.current = state
    useEffect(() => {
        switch (stateRef.current) {
            case 'hidden':
            case 'hidingDelay':
                if (show) {
                    setState('shown')
                    return
                } else {
                    return
                }
            case 'shown':
                if (show) {
                    return
                } else {
                    setState('hidingDelay')
                    const handle = setTimeout(() => {
                        setState('hidden')
                    }, hideDelay)
                    return () => {
                        clearTimeout(handle)
                    }
                }
        }
    }, [show, hideDelay])

    return {
        hidden: false,
        shown: true,
        hidingDelay: true,
    }[state]
}
