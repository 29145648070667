import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TestProps } from '@wh-components/core/common'
import { SpaceProps } from '@wh-components/system/space'
import { LinkGroup } from '@wh/common/chapter/types/SeoMetaData'
import { ResponsiveValue } from '@wh-components/system'
import { useResponsiveValue } from '@wh-components/core/utilities/responsive'
import { DynamicRendering } from '@wh/common/chapter/components/DynamicRendering/DynamicRendering'
import { Box } from '@wh-components/core/Box/Box'
import { Collapse } from '@wh/common/chapter/components/Collapse/Collapse'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'

interface LinkVomitProps extends TestProps, SpaceProps {
    linkGroups: LinkGroup[]
    alignLinks?: ResponsiveValue<'row' | 'column'>
    taggingData?: TaggingData
}

export const LinkVomit: FunctionComponent<LinkVomitProps> = ({ linkGroups, alignLinks = 'row', taggingData, ...props }) => {
    // @ts-ignore
    const alignLinksValue: 'row' | 'column' = useResponsiveValue(alignLinks, 'row')

    return (
        <DynamicRendering<HTMLDivElement> renderPlaceholder={(ref) => <Box ref={ref} />}>
            <Box display="flex" gap={{ tablet: 'm' }} flexDirection={{ phone: 'column', tablet: 'row' }} {...props}>
                {linkGroups.map(({ title, links }) => (
                    <Collapse key={title} title={title} flex={1}>
                        <VomitLinks alignLinks={alignLinksValue}>
                            {links.map(({ name, link, taggingAction }) => (
                                <ServerRoutingAnchorLink
                                    key={name}
                                    type="anchor"
                                    href={
                                        !link.startsWith('/iad/') &&
                                        !link.startsWith('/jobs/') &&
                                        !link.includes('wohnkredite.willhaben.at')
                                            ? `/iad/${link}`
                                            : link
                                    }
                                    color="palette.verydarkgrey"
                                    onClick={() => taggingAction && callActionEvent(taggingAction, taggingData)}
                                >
                                    {name}
                                </ServerRoutingAnchorLink>
                            ))}
                        </VomitLinks>
                    </Collapse>
                ))}
            </Box>
        </DynamicRendering>
    )
}

const VomitLinks = styled.div<{ alignLinks: 'row' | 'column' }>`
    width: 100%;
    display: flex;
    flex-direction: ${(p) => p.alignLinks};
    flex-wrap: ${(p) => (p.alignLinks === 'row' ? 'wrap' : undefined)};
    margin-top: -${(p) => p.theme.space.s}px;

    > * {
        margin-top: ${(p) => p.theme.space.s}px;
        margin-right: ${(p) => (p.alignLinks === 'row' ? `${p.theme.space.m}px` : undefined)};
    }

    ${(p) => p.theme.media.only.phone} {
        padding-bottom: ${(p) => p.theme.space.m}px;
        margin-top: -${(p) => p.theme.space.sm}px;

        > * {
            margin-top: ${(p) => p.theme.space.sm}px;
        }
    }
`
