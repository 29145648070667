import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { system } from '@wh-components/system'

interface MultiLineTextTruncationProps {
    lineHeight: number
    lineCount: number
    bgColor: string
    children: ReactNode
}

const background = system({
    bgColor: {
        property: 'background',
        scale: 'colors',
    },
})

export const MultiLineTextTruncation = (props: MultiLineTextTruncationProps) => (
    <MultiLineTextTruncationOuter {...props}>
        <MultiLineTextTruncationInner {...props}>{props.children}</MultiLineTextTruncationInner>
    </MultiLineTextTruncationOuter>
)

const MultiLineTextTruncationOuter = styled.div<MultiLineTextTruncationProps>`
    /* extra margin for older browsers that is needed by MultiLineTextTruncationInner for the "..." */
    margin-right: 1em;

    @supports (-webkit-line-clamp: ${(p) => p.lineCount}) {
        /* negate fallback rule from above */
        margin-right: 0;
    }
`

const MultiLineTextTruncationInner = styled.div<MultiLineTextTruncationProps>`
    /* hide text if it more than N lines  */
    overflow: hidden;

    /* -------------------------------------------------------------- */
    /* multiline text truncation for older browsers http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/ */
    /* -------------------------------------------------------------- */

    /* for set '...' in absolute position */
    position: relative;
    /* use this value to count block height */
    line-height: ${(props) => `${props.lineHeight}rem`};
    /* max-height = line-height (1.2) * lines max number (3) */
    max-height: ${(props) => `${props.lineCount * props.lineHeight}rem`};
    /* fix problem when last visible word doesn't adjoin right side  */
    text-align: justify;
    /* place for '...' */
    margin-right: -1em;
    padding-right: 1em;
    word-break: break-word;

    /* create the ... */
    &::before {
        /* points in the end */
        content: '...';
        /* absolute position */
        position: absolute;
        /* set position to right bottom corner of block */
        right: 0;
        bottom: 0;
        /* hide overflowing text in case it contains long words that are longer than the width of the div */
        ${background}/* injected background  */

        /* possible alternative with fadeout
        right: 0;
        bottom: 0;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0px;
        background: linear-gradient(transparent 25px, white);
        */
    }

    /* hide ... if we have text, which is less than or equal to max lines */
    &::after {
        /* points in the end */
        content: '';
        /* absolute position */
        position: absolute;
        /* set position to right bottom corner of text */
        right: 0;
        /* set width and height */
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        /* bg color = bg color under block */
        ${background}/* injected background  */
    }

    /* -------------------------------------------------------------- */
    /* end of multiline text truncation for older browsers            */
    /* -------------------------------------------------------------- */

    @supports (-webkit-line-clamp: ${(p) => p.lineCount}) {
        /* -------------------------------------------------------------- */
        /* negate fallback rules from above                               */
        /* -------------------------------------------------------------- */

        position: static;
        line-height: inherit;
        max-height: none;
        text-align: inherit;
        margin-right: 0;
        padding-right: 0;

        &::before {
            content: '';
            position: static;
            right: auto;
            bottom: auto;
            background: none;
        }

        &::after {
            content: '';
            position: static;
            right: auto;
            width: auto;
            height: auto;
            margin-top: auto;
            background: none;
        }

        /* -------------------------------------------------------------- */
        /* end of negating fallback rules                                 */
        /* -------------------------------------------------------------- */

        /* use -webkit-line-clamp which would be compatible with chrome, safari, opera, android, ios, edge>=17 https://caniuse.com/#feat=css-line-clamp */
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        -webkit-line-clamp: ${(p) => p.lineCount};
    }
`
