import { removeUndefined } from '../lib/functionalHelpers'
import { fetcher } from './fetcher'

type EventType =
    | 'call'
    | 'atz-in-motion-first-manual-image-change'
    | 'atz-in-motion-clicked'
    | 'atz-in-motion-viewed'
    | 'virtual-tour-link-clicked'
    | 'atz-shared'
    | 'phone-number-button-clicked'
    | 'top-atz-result-page-clicked'
    | 'top-atz-start-page-clicked'
    | 'top-atz-start-page-viewed'
    | 'top-atz-result-page-viewed'
    | 'pole-position-clicked'
    | 'pole-position-viewed'
    | 'homepage-clicked'

export const logBdsEvent = (adId: number | string, action: EventType, payloadKey?: string, payloadValue?: string): Promise<void> =>
    fetcher<void>(
        `/iad/logevent/atz/${adId}/${action}?${new URLSearchParams(
            removeUndefined({
                payloadKey: payloadKey,
                payloadValue: payloadValue,
            }),
        )}`,
        {
            method: 'POST',
            credentials: 'include',
        },
    ).catch(() => {}) /* fire and forget, we don't care about errors for event logging */
