import React, { FunctionComponent } from 'react'
import { WithContext, Thing } from 'schema-dts'

interface JsonLdScriptElementProps {
    jsonLd: WithContext<Thing>
}

export const JsonLdScriptElement: FunctionComponent<JsonLdScriptElementProps> = ({ jsonLd }) => (
    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} />
)
