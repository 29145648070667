import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react'

export type DmpEventName =
    | 'MessageSent'
    | 'TapSendMessage'
    | 'TapCallSeller'
    | 'TapSaveAd'
    | 'TapActivateSearchAgent'
    | 'UserAction'
    | 'TapApplyNow'
    | 'TapCloseJobWidget'
    | 'TapAdJobRecommendationWidget'
    | 'TapShowAllJobRecommendationWidget'
    | 'TapTextlink'
    | 'TapWidget'
    | 'TapServicebox'
    | 'TeaserClick'

export const payliveryDmpEventNameValues = [
    'PayliveryOfferMadeByBuyer',
    'PayliveryOfferMadeBySeller',
    'PayliveryOfferAcceptedByBuyer',
    'PayliveryItemPaidByBuyer',
    'PayliveryOfferAcceptedBySeller',
] as const
export type PayliveryDmpEventName = (typeof payliveryDmpEventNameValues)[number]

export interface PayliveryDmpMessage {
    eventName: PayliveryDmpEventName
    paylivery: {
        price: number
    }
    meta: Record<string, string>
}

export interface DmpUserActionParameters {
    eventName?: DmpEventName | PayliveryDmpEventName
}

export interface DmpParameters extends DmpUserActionParameters {
    [key: string]: number | boolean | string | string[] | number[] | Record<string, string | number> | undefined
}

export interface DmpUserIdentities {
    [key: string]: string | undefined
}

export type TrackCustomDmpEventFunction = (eventName: DmpEventName, additionalParameters?: DmpParameters) => Promise<void>

export type DmpState = {
    trackCustomDmpEvent: TrackCustomDmpEventFunction
}

export const DmpStateContext = React.createContext<DmpState>({
    trackCustomDmpEvent: () => {
        console.error('in order to use trackCustomDmpEvent from the DmpStateContext, you need to wrap your components in DmpStateProvider')
        return Promise.resolve()
    },
})

export const DmpStateProvider: FunctionComponent<PropsWithChildren<{ trackCustomDmpEvent: TrackCustomDmpEventFunction }>> = (props) => {
    const value = useMemo(() => ({ trackCustomDmpEvent: props.trackCustomDmpEvent }), [props.trackCustomDmpEvent])
    return <DmpStateContext.Provider value={value}>{props.children}</DmpStateContext.Provider>
}
