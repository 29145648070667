import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'
import { BaseStickyHeaderProps, BaseStickyHeader } from './BaseStickyHeader'

const transitionDuration = 300

export const StickyHeader: FunctionComponent<Omit<BaseStickyHeaderProps, 'hideAnimationDuration'>> = ({
    children,
    showStickyHeader,
    testId,
}) => (
    <BaseStickyHeader
        showStickyHeader={showStickyHeader}
        testId={testId}
        hideAnimationDuration={transitionDuration}
        css={css`
            z-index: ${(p) => p.theme.zIndices.stickyContent};
            transition: transform ${transitionDuration / 1000}s 0s;
            transform: ${showStickyHeader ? 'translateY(0px)' : 'translateY(-110%)'};
            ${(p) => p.theme.media.print} {
                display: none;
            }
        `}
    >
        {children}
    </BaseStickyHeader>
)
