import React, { FunctionComponent } from 'react'
import { SuccessAlert } from '@wh/common/chapter/components/Alerts/SuccessAlert'
import { Box } from '@wh-components/core/Box/Box'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'

export const SaveAdAlert: FunctionComponent = () => {
    return (
        <SuccessAlert
            title="Die Anzeige wurde hinzugefügt"
            message={
                <Box>
                    Deine Merklisten findest du{' '}
                    <ClientRoutingAnchorLink type="anchor" href="/iad/myprofile/myfindings" testId="my-savedads-link">
                        hier.
                    </ClientRoutingAnchorLink>
                </Box>
            }
        />
    )
}
