import React, { FunctionComponent, useContext } from 'react'
import { CdcAdvertSummaryProps } from '@bbx/search-journey/common/components/AdvertSummary/CdcAdvertSummaryProps'
import { JobAdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/JobsAdvertSummary'
import { findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { DmpStateContext } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'

export const CdcJobAdvertSummaryComponent: FunctionComponent<CdcAdvertSummaryProps> = ({
    ad,
    onClickTaggingEvent,
    isActive,
    color,
    jobsVariant,
}) => {
    const { trackCustomDmpEvent } = useContext(DmpStateContext)

    return (
        <JobAdvertSummaryComponent
            id={ad.id}
            key={ad.description}
            title={ad.description}
            footerText={ad.postalName}
            teaserAttribute={ad.teaserAttributes?.map((teaserAttribute) => teaserAttribute.value).join(', ')}
            imageUrl={ad.advertImageList?.[0]?.mainImageUrl}
            href={findContextLinkWithIdFromArray('adDetail', ad.contextLinkList)?.uri}
            testIdPrefix="jobs-widget"
            color={color}
            inactiveColor={color}
            onClickTaggingEvent={() => {
                onClickTaggingEvent?.()
                trackCustomDmpEvent('UserAction', { eventName: 'TapAdJobRecommendationWidget' })
            }}
            jobsVariant={jobsVariant}
            isActive={isActive}
        />
    )
}
