import {
    genericValidationErrorMessages,
    hasMaxLength,
    hasMinLength,
    isNonEmpty,
    isNumberString,
    isValidPhoneNumberString,
} from '@wh/common/chapter/lib/validation'
import { removeUndefined } from '@wh/common/chapter/lib/functionalHelpers'
import { UserProfileData } from '@wh/common/chapter/types/user'
import { FormikErrors } from 'formik'

export { emailValidationMessage } from '@wh/common/chapter/lib/validation'

export const validationErrorMessages = {
    ...genericValidationErrorMessages,
    EMPTY_ERROR: 'Dieses Feld muss ausgefüllt werden.',
    EMPTY_PASSWORD: 'Bitte gib dein Passwort ein.',
    EMPTY_EMAIL: 'Bitte gib deine E-Mail-Adresse ein.',
    EMPTY_NAME: 'Bitte gib deinen Vornamen ein.',
    EMPTY_PHONE_NUMBER: 'Bitte Telefonnummer eingeben.',
    MIN_PHONE_NUMBER_CHARS_ERROR: 'Telefonnummer zu kurz.',
    DISALLOW_HTML: 'Bitte gib kein HTML ein.',
    MIN_8_CHARS_COUNT_ERROR: 'Bitte gib mindestens 8 Zeichen ein.',
    MIN_2_CHARS_COUNT_ERROR: 'Bitte gib mindestens 2 Zeichen ein.',
    MAX_6_CHARS_COUNT_ERROR: 'Max. 6 Ziffern.',
    MAX_10_CHARS_COUNT_ERROR: 'Max. 10 Ziffern.',
    MAX_30_CHARS_COUNT_ERROR: 'Bitte gib maximal 30 Zeichen ein.',
    MAX_255_CHARS_COUNT_ERROR: 'Bitte gib maximal 255 Zeichen ein.',
    MAX_40_CHARS_COUNT_ERROR: 'Maximal 40 Zeichen.',
    MAX_256_CHARS_COUNT_ERROR: 'Bitte gib maximal 256 Zeichen ein.',
    MAX_10000_CHARS_COUNT_ERROR: 'Das Textfeld darf maximal 10.000 Zeichen beinhalten.',
    ONLY_NUMBERS_ALLOWED: 'Bitte nur Zahlen eingeben.',
    ONLY_NUMBERS_ALLOWED_SHORT: 'Nur Ziffern.',
    INVALID_CHARACTER: 'Nicht erlaubte Zeichen.',
    INVALID_EMAIL: 'Bitte gib eine gültige E-Mail-Adresse an.',
    INVALID_PHONENUMBER: 'Bitte gib eine gültige Telefonnummer an.',
    REASON_REQUIRED: 'Bitte wähle einen Grund aus.',
}

// Note: order matter, please do not sort
export const internationalPhonePrefixes: Array<string> = [
    '+43',
    '+298',
    '+30',
    '+32',
    '+34',
    '+352',
    '+353',
    '+354',
    '+355',
    '+356',
    '+357',
    '+358',
    '+359',
    '+36',
    '+377',
    '+378',
    '+380',
    '+381',
    '+382',
    '+383',
    '+385',
    '+386',
    '+387',
    '+389',
    '+39',
    '+41',
    '+420',
    '+421',
    '+423',
    '+45',
    '+46',
    '+47',
    '+48',
    '+49',
    '+90',
]

export const messageValidationMessage = (isMandatory: boolean, message?: string) => {
    if (isMandatory && !isNonEmpty(message)) {
        return validationErrorMessages.EMPTY_ERROR
    } else if (!hasMaxLength(10000, message)) {
        return validationErrorMessages.MAX_10000_CHARS_COUNT_ERROR
    } else {
        return undefined
    }
}

export const optionalPhoneNumberValidationMessage = (phoneNumberString?: string) => {
    if (!isNonEmpty(phoneNumberString)) {
        return undefined
    } else {
        const isInternational = isInternationalPhoneNumber(phoneNumberString)
        const [_prefix, phoneNumber] = splitInternationalPhoneNumber(phoneNumberString)
        if (!isValidPhoneNumberString(phoneNumber)) {
            return isInternational ? validationErrorMessages.INVALID_PHONENUMBER : validationErrorMessages.ONLY_NUMBERS_ALLOWED
        }
    }
    return undefined
}

export const requiredPhoneNumberValidationMessage = (phoneNumberString?: string) => {
    if (!isNonEmpty(phoneNumberString)) {
        return validationErrorMessages.EMPTY_PHONE_NUMBER
    } else {
        const isInternational = isInternationalPhoneNumber(phoneNumberString)
        const [_prefix, phoneNumber] = splitInternationalPhoneNumber(phoneNumberString)
        if (!isValidPhoneNumberString(phoneNumber)) {
            return isInternational ? validationErrorMessages.INVALID_PHONENUMBER : validationErrorMessages.ONLY_NUMBERS_ALLOWED
        } else if (!hasMinLength(5, phoneNumber)) {
            return validationErrorMessages.MIN_PHONE_NUMBER_CHARS_ERROR
        }
    }
    return undefined
}

export const firstNameValidation = (firstName?: string | null, isOptional: boolean = false, disallowNumbers: boolean = false) => {
    if (!isOptional && !isNonEmpty(firstName ?? undefined)) {
        return validationErrorMessages.EMPTY_ERROR
    } else if (firstName!.indexOf('@') > -1) {
        return validationErrorMessages.INVALID_CHARACTER
    } else if (disallowNumbers && firstName && /\d/.test(firstName)) {
        return validationErrorMessages.INVALID_CHARACTER
    } else if (!isOptional && !hasMinLength(2, firstName ?? undefined)) {
        return validationErrorMessages.MIN_2_CHARS_COUNT_ERROR
    } else if (!hasMaxLength(40, firstName ?? undefined)) {
        return validationErrorMessages.MAX_40_CHARS_COUNT_ERROR
    } else {
        return undefined
    }
}

export const lastNameValidation = (lastName?: string | null, disallowNumbers: boolean = false) => {
    if (lastName && lastName.indexOf('@') > -1) {
        return validationErrorMessages.INVALID_CHARACTER
    } else if (!hasMaxLength(40, lastName ?? undefined)) {
        return validationErrorMessages.MAX_40_CHARS_COUNT_ERROR
    } else if (disallowNumbers && lastName && /\d/.test(lastName)) {
        return validationErrorMessages.INVALID_CHARACTER
    } else {
        return undefined
    }
}

export const addressPostCodeValidation = (addressPostcode?: string | null) => {
    if (addressPostcode && addressPostcode.trim().length > 6) {
        return validationErrorMessages.MAX_6_CHARS_COUNT_ERROR
    } else if (addressPostcode && !isNumberString(addressPostcode)) {
        return validationErrorMessages.ONLY_NUMBERS_ALLOWED_SHORT
    } else {
        return undefined
    }
}

export const userProfileAddress3Validation = (address3?: string | null) => {
    if (address3 && (address3.indexOf('@') > -1 || /\d/.test(address3))) {
        return validationErrorMessages.INVALID_CHARACTER
    } else if (!hasMaxLength(40, address3 ?? undefined)) {
        return validationErrorMessages.MAX_40_CHARS_COUNT_ERROR
    } else {
        return undefined
    }
}

export const validateUpdateUserProfileDataWithoutPhoneNumber = async (values: UserProfileData): Promise<FormikErrors<UserProfileData>> => {
    const errors: { firstName?: string; lastName?: string; address3?: string; addressPostcode?: string; telephone?: string } = {}

    errors.firstName = firstNameValidation(values.firstName ?? undefined, false, true)
    errors.lastName = lastNameValidation(values.lastName ?? undefined, true)
    errors.addressPostcode = addressPostCodeValidation(values.addressPostcode ?? undefined)
    errors.address3 = userProfileAddress3Validation(values.address3 ?? undefined)

    return removeUndefined(errors)
}

export const reportReasonValidationMessage = (isMandatory: boolean, message?: string) => {
    if (isMandatory && !isNonEmpty(message)) {
        return validationErrorMessages.REASON_REQUIRED
    } else {
        return undefined
    }
}

export const isInternationalPhoneNumber = (input: string) => {
    return input.startsWith('+')
}

export const splitInternationalPhoneNumber = (
    input: string,
    prefixes = internationalPhonePrefixes,
): [undefined, string] | [string, string] => {
    // Note: no need to sort the international phone prefix as they cannot overlap meaning there cannot be +92 and +92x as prefix
    const prefix = prefixes.find((p) => input.startsWith(p))
    return prefix ? [prefix, input.substring(prefix.length)] : [undefined, input]
}
