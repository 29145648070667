import React, { FunctionComponent, Fragment } from 'react'
import { css } from 'styled-components'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { BreadcrumbsItem } from '@bbx/common/types/ad-detail/AdvertDetails'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import ArrowTinyRight from '@wh-components/icons/ArrowtinyRight'
import { BreadcrumbList, ListItem, WithContext } from 'schema-dts'
import { captureException } from '@wh/common/chapter/components/Sentry/sentry'
import { JsonLdScriptElement } from '@wh/common/chapter/components/JsonLd/JsonLdScriptElement'

export interface BreadcrumbsProps {
    breadcrumbsList: BreadcrumbsItem[] | null | undefined
    jsonLdId?: string
    hideJsonLd?: boolean
}

export const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({ breadcrumbsList, jsonLdId, hideJsonLd }) => {
    return breadcrumbsList?.length ? (
        <Fragment>
            {!hideJsonLd && <BreadcrumbsJsonLd breadcrumbsList={breadcrumbsList} jsonLdId={jsonLdId} />}
            <Box display="flex" flexWrap="wrap">
                {breadcrumbsList.map((item, index) => (
                    <Box key={item.displayName} display="flex" alignItems="center">
                        {!!index && <ArrowTinyRight marginHorizontal="xs" size="xxsmall" />}
                        {item.seoUrl ? (
                            <ServerRoutingAnchorLink
                                type="anchor"
                                href={item.seoUrl}
                                color="palette.verydarkgrey"
                                testId={`breadcrumbs-item-level-${index}`}
                            >
                                <Text as="p" fontSize="s">
                                    {item.displayName}
                                </Text>
                            </ServerRoutingAnchorLink>
                        ) : (
                            <Text
                                as="p"
                                fontSize="s"
                                color="palette.elephant"
                                css={css`
                                    cursor: default;
                                `}
                            >
                                {item.displayName}
                            </Text>
                        )}
                    </Box>
                ))}
            </Box>
        </Fragment>
    ) : null
}

export const BreadcrumbsJsonLd: FunctionComponent<BreadcrumbsProps> = ({ breadcrumbsList, jsonLdId = 'breadcrumbs' }) => {
    try {
        const breadcrumbItemsJsonLd: ListItem[] =
            breadcrumbsList?.map((breadcrumb, index) => ({
                '@type': 'ListItem',
                position: index + 1,
                name: breadcrumb.displayName,
                item: {
                    '@type': 'Thing',
                    id: breadcrumb.seoUrl,
                    url: breadcrumb.seoUrl,
                },
            })) ?? []

        const breadcrumbListJsonLd: WithContext<BreadcrumbList> = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            '@id': `#${jsonLdId}`,
            itemListElement: breadcrumbItemsJsonLd,
        }

        return <JsonLdScriptElement key="jsonld-breadcrumbs" jsonLd={breadcrumbListJsonLd} />
    } catch (error) {
        captureException(error)
        return null
    }
}
