import { SAVED_SEARCH_RESULT_STORAGE_KEY } from '@wh/common/chapter/lib/localStorage'
import { SavedSearchResult } from './searchResult-sessionStorage-types'

export const getSearchResultFromSessionStorage = () => {
    try {
        const searchResultJSON = sessionStorage.getItem(SAVED_SEARCH_RESULT_STORAGE_KEY)

        if (searchResultJSON) {
            return JSON.parse(searchResultJSON) as SavedSearchResult
        }
    } catch (error) {
        console.log(error)
    }

    return undefined
}
