import { useCallback, useContext, useEffect, useRef } from 'react'
import { OptimizelyContext, OptimizelyDecision, useDecision, useExperiment, useFeature } from '@optimizely/react-sdk'
import { EventTags } from '@optimizely/optimizely-sdk'
import { useScreenSize } from '../components/UserAgentProvider/useUserAgent'
import { isUserLoggedIn, useProfileData } from '../components/GlobalStateProvider/GlobalStateProvider'

interface UserAttributes {
    [key: string]: unknown
}

export const useOptimizelyDecision = (
    featureKey: string,
    overrideAttributes?: UserAttributes,
    autoUpdate = false,
): Partial<OptimizelyDecision> => {
    const [profileData] = useProfileData()
    const screenSize = useScreenSize()
    const [decision] = useDecision(
        featureKey,
        { autoUpdate },
        {
            overrideAttributes: { screenSize, isLoggedIn: isUserLoggedIn(profileData), ...overrideAttributes },
        },
    )
    return decision
}

export const useOptimizelyExperiment = (featureKey: string, overrideAttributes?: UserAttributes): string | null => {
    const [profileData] = useProfileData()
    const screenSize = useScreenSize()
    const [variation] = useExperiment(featureKey, undefined, {
        overrideAttributes: { screenSize, isLoggedIn: isUserLoggedIn(profileData), ...overrideAttributes },
    })
    return variation
}

export const useOptimizelyFeature = (featureKey: string, overrideAttributes?: UserAttributes): boolean => {
    const [profileData] = useProfileData()
    const screenSize = useScreenSize()
    const [enabled] = useFeature(featureKey, undefined, {
        overrideAttributes: { screenSize, isLoggedIn: isUserLoggedIn(profileData), ...overrideAttributes },
    })
    return enabled
}

export const useOptimizelyTrack = (overrideAttributes?: UserAttributes, eventTagsCommon?: EventTags) => {
    const [profileData] = useProfileData()
    const { optimizely } = useContext(OptimizelyContext)
    const screenSize = useScreenSize()

    // Refs are needed to allow a stable reference in the callback function below
    // Otherwise rerenders would be triggered that causes false metrics such as in useTrackTimeOnPage
    const profileDataRef = useRef(profileData)
    profileDataRef.current = profileData
    const optimizelyRef = useRef(optimizely)
    optimizelyRef.current = optimizely
    const screenSizeRef = useRef(screenSize)
    screenSizeRef.current = screenSize

    // this will only send data to optimizely servers when the user id is available in the OptimizelyContext
    return useCallback(
        (val: string, eventTagsCustom?: EventTags) =>
            optimizelyRef.current?.track(
                val,
                undefined,
                {
                    screenSize: screenSizeRef.current,
                    isLoggedIn: isUserLoggedIn(profileDataRef.current),
                    ...overrideAttributes,
                },
                { ...eventTagsCommon, ...eventTagsCustom },
            ),
        [eventTagsCommon, overrideAttributes],
    )
}

export const getVariableByDecision = (decision: Partial<OptimizelyDecision>, variable: string): string | null => {
    if (decision.variables && decision.variationKey !== 'off') {
        return decision.variables[variable] as string
    }
    return null
}

export const useTrackTimeOnPage = () => {
    // trackEvent gives a stable reference which will not trigger rerenders
    const trackEvent = useOptimizelyTrack()
    useEffect(() => {
        const start = new Date()
        return () => {
            const end = new Date()
            const timeSpentInSeconds = (end.valueOf() - start.valueOf()) / 1000

            trackEvent('time_spent', { value: timeSpentInSeconds })
        }
    }, [trackEvent])
}

export const useTrackTimeOnPageConditionally = (shouldReallyTrack: boolean) => {
    // trackEvent gives a stable reference which will not trigger rerenders
    const trackEvent = useOptimizelyTrack()
    useEffect(() => {
        const start = new Date()
        return () => {
            const end = new Date()
            const timeSpentInSeconds = (end.valueOf() - start.valueOf()) / 1000

            if (shouldReallyTrack) {
                trackEvent('time_spent', { value: timeSpentInSeconds })
            }
        }
    }, [shouldReallyTrack, trackEvent])
}

const OptimizelyTextKeys = {
    displayText: 'display_text',
    displayTextMobile: 'display_text_mobile',
}

export interface OptimizelyResponsiveText {
    displayText: string
    displayTextMobile: string
}
export const getResponsiveTextVariableObjectByDecision = (decision: Partial<OptimizelyDecision>): OptimizelyResponsiveText | null => {
    if (!decision.variables || decision.variationKey === 'off' || !decision.enabled) {
        return null
    }

    const responsiveOptimizelyText: OptimizelyResponsiveText = { displayText: '', displayTextMobile: '' }
    responsiveOptimizelyText.displayText = decision.variables[OptimizelyTextKeys.displayText] as string
    responsiveOptimizelyText.displayTextMobile = decision.variables[OptimizelyTextKeys.displayTextMobile] as string
    return responsiveOptimizelyText
}
