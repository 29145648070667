import {
    SavedAdsListType,
    SavedAdsType,
    SavedAdsItemList,
    SavedAdsItem,
    SavedAdsItemComment,
    SavedAdsAdIds,
    SavedAdsAdIdsRecord,
} from '@bbx/common/types/savedAds'
import { fetcher } from '@wh/common/chapter/api/fetcher'
import { getBbxCookiesFromRequest } from '@wh/common/chapter/types/cookies'
import { Request } from 'express'

// savedAdsList = List of all Merkliste(s)
// savedAds = A Merkliste
// savedAdsItem = An item (ad) in a Merkliste
// savedAdsItemList = All items (ad) in a Merkliste

/** Get all Merklisten */
export const getSavedAdsList = (loginId: number, request?: Request): Promise<SavedAdsListType> => {
    return fetcher<SavedAdsListType>(`/iad/userfolders/${loginId}`, {
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
        cookies: getBbxCookiesFromRequest(request),
    })
}

/** Get a specific Merkliste */
export const getSavedAds = (loginId: number, savedAdsId: number, request?: Request): Promise<SavedAdsType> => {
    return fetcher<SavedAdsType>(`/iad/userfolders/${loginId}/${savedAdsId}`, {
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
        cookies: getBbxCookiesFromRequest(request),
    })
}

/** Get all AdIds of all Merklisten */
export const getAllSavedAdsAdIds = (loginId: number, request?: Request): Promise<SavedAdsAdIdsRecord> => {
    return fetcher<SavedAdsAdIds>(`/iad/userfolders/all/${loginId}`, {
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
        cookies: getBbxCookiesFromRequest(request),
    }).then(transformSavedAdsAdIdsApiResponstAsRecord)
}

export const transformSavedAdsAdIdsApiResponstAsRecord = (savedAdsAdIds: SavedAdsAdIds) => {
    return savedAdsAdIds.adIds.reduce((acc: SavedAdsAdIdsRecord, curr) => {
        acc[curr.adId.toString()] = true
        return acc
    }, {})
}

/** Create a new Merkliste */
export const createSavedAds = (loginId: number, data: { name: string }): Promise<SavedAdsType> => {
    return fetcher<SavedAdsType>(`/iad/userfolders/${loginId}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
        body: JSON.stringify(data),
    })
}

/** Save an Ad to a Merkliste */
export const saveSavedAdsItem = (loginId: number, savedAdsId: number, adId: number): Promise<SavedAdsType> => {
    return fetcher<SavedAdsType>(`/iad/userfolders/save/${loginId}/${savedAdsId}/${adId}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    })
}

/** Save a list of Ads to a Merkliste */
export const saveSavedAdsItems = (loginId: number, savedAdsId: number, data: SavedAdsItem[]): Promise<SavedAdsType> => {
    return fetcher<SavedAdsType>(`/iad/userfolders/${loginId}/${savedAdsId}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
        body: JSON.stringify(data),
    })
}

/** Add a comment to a specific item (ad) in a Merkliste */
export const addCommentToSavedAdsItem = (loginId: number, savedAdId: number, comment: string): Promise<SavedAdsItemComment> => {
    return fetcher<SavedAdsItemComment>(`/iad/userfolders/${loginId}/${savedAdId}/comments`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'text/plain',
        },
        body: JSON.stringify(comment),
    })
}

/** Delete a Merkliste */
export const deleteSavedAds = (loginId: number, savedAdsId: number): Promise<void> => {
    return fetcher(`/iad/userfolders/${loginId}/${savedAdsId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    })
}

/** Delete a specific item (ad) from Merkliste */
export const deleteSavedAdsItem = (loginId: number, savedAdsId: number, savedAdId: number): Promise<void> => {
    return fetcher(`/iad/userfolders/${loginId}/${savedAdsId}/${savedAdId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    })
}

/** Delete a specific item (ad) from Merkliste without folderId */
export const deleteSavedAdsItemWithoutFolderId = (loginId: number, adId: number): Promise<void> => {
    return fetcher(`/iad/userfolders/savedAd/${loginId}/${adId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    })
}

/** Bulk delete Ads from Merkliste */
export const bulkDeleteSavedAdsItems = (loginId: number, savedAdsId: number): Promise<void> => {
    return fetcher(`/iad/userfolders/bulkdelete/${loginId}/${savedAdsId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    })
}

/** Delete a comment from a specific item (ad) in a Merkliste */
export const deleteCommentFromSavedAdsItem = (loginId: number, savedAdId: number, commentId: number): Promise<void> => {
    return fetcher<void>(`/iad/userfolders/${loginId}/${savedAdId}/comments/${commentId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    })
}

/** Update a Merkliste */
export const updateSavedAds = (loginId: number, savedAdsId: number, data: { name: string }): Promise<SavedAdsType> => {
    return fetcher<SavedAdsType>(`/iad/userfolders/${loginId}/${savedAdsId}`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
        body: JSON.stringify(data),
    })
}

/** Move a a specific item (ad) in a Merkliste to another Merkliste */
export const moveSavedAdsItem = (loginId: number, adId: number | string, savedAdsId: number): Promise<SavedAdsType> => {
    return fetcher<SavedAdsType>(`/iad/userfolders/savedAd/${loginId}/${adId}`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'text/plain',
        },
        body: JSON.stringify(savedAdsId),
    })
}

/** Renew an expired item (ad) in a Merkliste */
export const renewSavedAdsItem = (url: string): Promise<SavedAdsItemList> => {
    return fetcher<SavedAdsItemList>(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    })
}
export const removeDeletedAds = (loginId: number, folderId: number): Promise<void> => {
    return fetcher<void>(`/iad/userfolders/${loginId}/${folderId}/deletedAds`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    })
}
