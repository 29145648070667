import React, { FunctionComponent, PropsWithChildren } from 'react'
import { Box, BoxProps } from '@wh-components/core/Box/Box'
import { css } from 'styled-components'
import { useHideDelay } from '@wh/common/chapter/hooks/useHideDelay'

export interface BaseStickyHeaderProps extends PropsWithChildren {
    showStickyHeader: boolean
    testId: string
    hideAnimationDuration: number
}

export const BaseStickyHeader: FunctionComponent<BaseStickyHeaderProps & BoxProps> = ({
    children,
    showStickyHeader,
    testId,
    hideAnimationDuration,
    ...props
}) => {
    const visibilityHidden = !useHideDelay(showStickyHeader, hideAnimationDuration)

    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            maxWidth="970px"
            width="100%"
            aria-hidden={!showStickyHeader}
            data-is-shown={showStickyHeader}
            testId={testId}
            {...props}
            css={css`
                /* makes children untabbable */
                visibility: ${visibilityHidden ? 'hidden' : undefined};
            `}
        >
            {children}
        </Box>
    )
}
