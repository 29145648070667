import React, { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { Button } from '@wh-components/core/Button/Button'
import { getSavedAdsList, saveSavedAdsItem } from '@bbx/search-journey/common/api/savedAdsListApiClient'
import { SavedAdsType } from '@bbx/common/types/savedAds'
import { Radio } from '@wh-components/core/FormElements/Radio/Radio'
import { OPEN_SAVE_AD_MODAL_STORAGE_KEY } from '@wh/common/chapter/lib/localStorage'
import { Box } from '@wh-components/core/Box/Box'
import { Modal } from '@wh-components/core/Modal/Modal'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { storageAvailable } from '@wh/common/chapter/lib/storageAvailable'
import { toast } from '@wh-components/core/Toast/Toast'
import { ApiErrorAlert } from '@wh/common/chapter/components/Alerts/ApiErrorAlert'
import { SaveAdAlert } from '@bbx/search-journey/common/components/SaveAd/SaveAdAlert'

export interface SaveAdModalProps {
    savedAdsUiState: SavedAdsUiState
    setSavedAdsUiState: (state: SavedAdsUiState) => void
    onSavedAd?: (adId: string) => void
    loginId: number | undefined
    onSavedTaggingEvent?: (adId: string) => Promise<void>
}

export interface SavedAdsUiState {
    state: SavedAdsUiStateOption
}

export type SavedAdsUiStateOption = 'idle' | 'showModal'

interface SaveAdModalInfo {
    adId: string
    title: string
}

export const SaveAdModal: FunctionComponent<SaveAdModalProps> = ({
    savedAdsUiState,
    setSavedAdsUiState,
    onSavedAd,
    loginId,
    onSavedTaggingEvent,
}) => {
    const [lists, setLists] = useState<SavedAdsType[]>([])
    const [adInfo, setAdInfo] = useState<SaveAdModalInfo | undefined>(undefined)
    const [selectedListId, setSelectedListId] = useState<number | undefined>(undefined)
    const [submitting, setSubmitting] = useState<boolean>(false)

    useEffect(() => {
        async function loadLists() {
            if (loginId) {
                if (storageAvailable('localStorage')) {
                    const openSaveAdIdAndTitle = localStorage.getItem(OPEN_SAVE_AD_MODAL_STORAGE_KEY)

                    if (openSaveAdIdAndTitle) {
                        localStorage.removeItem(OPEN_SAVE_AD_MODAL_STORAGE_KEY)

                        try {
                            const savedAdsLists = (await getSavedAdsList(loginId)).advertFolders
                            setLists(savedAdsLists)
                            setSelectedListId(savedAdsLists[0].id)
                            const storedAdInfo = JSON.parse(openSaveAdIdAndTitle) as SaveAdModalInfo

                            if (storedAdInfo.adId && storedAdInfo.title) {
                                setAdInfo(storedAdInfo)
                                setSubmitting(false)
                                setSavedAdsUiState({ state: 'showModal' })
                            }
                        } catch {
                            // ignore error
                        }
                    }
                }
            }
        }

        loadLists()
    }, [loginId, setSavedAdsUiState])

    if (!adInfo) {
        return null
    }

    const saveAd = async () => {
        setSubmitting(true)
        if (selectedListId && loginId) {
            try {
                await saveSavedAdsItem(loginId, selectedListId, +adInfo.adId)

                toast(<SaveAdAlert />, { type: 'success' })
                setSavedAdsUiState({ state: 'idle' })

                onSavedAd?.(adInfo.adId)
                onSavedTaggingEvent?.(adInfo.adId)
            } catch (e) {
                const apiError = wrapInApiErrorIfNecessary(e)
                toast(<ApiErrorAlert error={apiError.errorResponse} />, { type: 'error', autoClose: false })
            }
        }
    }

    return (
        <Fragment>
            <Modal
                fullScreen={{ phone: true, tablet: false }}
                isOpen={savedAdsUiState.state === 'showModal'}
                onRequestClose={() => setSavedAdsUiState({ state: 'idle' })}
                header="Anzeige merken"
                testId="save-ad-modal"
            >
                <Box minWidth={{ tablet: '625px' }}>
                    <Box paddingBottom="m">{adInfo.title}</Box>

                    {lists.length > 1 && (
                        <Box paddingBottom="m">
                            <label htmlFor="radio-group-saved-ads-list" style={{ fontWeight: 'bold' }}>
                                Merkliste wählen
                            </label>

                            {lists.map((list) => {
                                return (
                                    <Radio
                                        key={list.id}
                                        id={`save-ad-modal-list-radio-${list.id}`}
                                        testId={`save-ad-modal-list-radio-${list.id}`}
                                        label={list.name}
                                        value={list.id}
                                        name="radio-group-saved-ads-list"
                                        defaultChecked={list.defaultFolder}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            if (e.currentTarget.value) {
                                                setSelectedListId(+e.currentTarget.value)
                                            }
                                        }}
                                    />
                                )
                            })}
                        </Box>
                    )}
                    <hr />

                    <Box
                        paddingTop="m"
                        display="flex"
                        flexDirection={{ phone: 'column-reverse', tablet: 'row' }}
                        justifyContent={{ tablet: 'space-between' }}
                    >
                        <div>
                            <Button
                                name="cancel"
                                size="large"
                                color="complimentary"
                                onClick={() => setSavedAdsUiState({ state: 'idle' })}
                                disabled={submitting}
                                width="100%"
                                testId="save-ad-modal-cancel-button"
                            >
                                Abbrechen
                            </Button>
                        </div>

                        <div>
                            <Button
                                name="save"
                                size="large"
                                onClick={saveAd}
                                disabled={submitting}
                                width="100%"
                                marginBottom={{ phone: 'm', tablet: 'none' }}
                                testId="save-ad-modal-save-button"
                            >
                                Anzeige merken
                            </Button>
                        </div>
                    </Box>
                </Box>
            </Modal>
        </Fragment>
    )
}
