import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { ContextLinkIdEnum, findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { buildSeoUrl, getSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { SAVED_SEARCH_RESULT_STORAGE_KEY } from '@wh/common/chapter/lib/localStorage'
import { BBX_MAX_PAGE_PARAM } from '@wh/common/chapter/lib/config/constants'
import { storageAvailable } from '@wh/common/chapter/lib/storageAvailable'
import { AdDetailLink, SavedSearchResult } from '@bbx/common/lib/searchResult-sessionStorage-types'

export { getSearchResultFromSessionStorage } from '@bbx/common/lib/searchResult-sessionStorage-reader'

export const saveSearchResultIntoSessionStorage = (searchResult: SearchResult) => {
    const searchResultLocation = locationFromSearchResult(searchResult)
    const detailLinks = convertSearchResultToAdDetailLinks(searchResult, searchResultLocation)
    const savedSearchResult: SavedSearchResult = {
        detailLinks: detailLinks,
        previousSearchResultPageContextLink: findContextLinkWithId(ContextLinkIdEnum.prevLink, searchResult.pagingLinksList),
        nextSearchResultPageContextLink:
            searchResult.pageRequested >= BBX_MAX_PAGE_PARAM
                ? undefined
                : findContextLinkWithId(ContextLinkIdEnum.nextLink, searchResult.pagingLinksList),
    }
    saveSearchResult(savedSearchResult)
}

export const prependPreviousPageSearchResultAndSaveIntoSessionStorage = (
    previousPageSearchResult: SearchResult,
    existingSavedSearchResult: SavedSearchResult,
) => {
    const searchResultLocation = locationFromSearchResult(previousPageSearchResult)
    const detailLinks = convertSearchResultToAdDetailLinks(previousPageSearchResult, searchResultLocation)
    const newSavedSearchResult: SavedSearchResult = {
        ...existingSavedSearchResult,
        previousSearchResultPageContextLink: findContextLinkWithId(ContextLinkIdEnum.prevLink, previousPageSearchResult.pagingLinksList),
        detailLinks: [...detailLinks, ...existingSavedSearchResult.detailLinks],
    }
    saveSearchResult(newSavedSearchResult)
    return newSavedSearchResult
}

export const appendNextPageSearchResultAndSaveIntoSessionStorage = (
    nextPageSearchResult: SearchResult,
    existingSavedSearchResult: SavedSearchResult,
) => {
    const searchResultLocation = locationFromSearchResult(nextPageSearchResult)
    const detailLinks = convertSearchResultToAdDetailLinks(nextPageSearchResult, searchResultLocation)
    const newSavedSearchResult: SavedSearchResult = {
        ...existingSavedSearchResult,
        nextSearchResultPageContextLink:
            nextPageSearchResult.pageRequested >= BBX_MAX_PAGE_PARAM
                ? undefined
                : findContextLinkWithId(ContextLinkIdEnum.nextLink, nextPageSearchResult.pagingLinksList),
        detailLinks: [...existingSavedSearchResult.detailLinks, ...detailLinks],
    }
    saveSearchResult(newSavedSearchResult)
    return newSavedSearchResult
}

const convertSearchResultToAdDetailLinks = (searchResult: SearchResult, searchResultLocation: string): AdDetailLink[] =>
    searchResult.advertSummaryList.advertSummary.map((advertSummary) => ({
        seoUrl: getSeoUrl(advertSummary),
        adId: advertSummary.id,
        searchResultLocation,
    }))

const locationFromSearchResult = (searchResult: SearchResult) => {
    return buildSeoUrl(findContextLinkWithId('resultListSeoNavigatorLink', searchResult.searchContextLinks)?.relativePath)
}

const saveSearchResult = (savedSearchResult: SavedSearchResult) => {
    if (storageAvailable('sessionStorage')) {
        sessionStorage.setItem(SAVED_SEARCH_RESULT_STORAGE_KEY, JSON.stringify(savedSearchResult))
    }
}
