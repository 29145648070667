import React, { Fragment, FunctionComponent, useCallback, useState } from 'react'
import { SavedAdsType } from '@bbx/common/types/savedAds'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import * as savedAdsApiClient from '@bbx/search-journey/common/api/savedAdsListApiClient'
import { redirectToLoginPage } from '@wh/common/chapter/lib/routing/bbxRouter'
import { ActionSheet } from '@wh-components/core/ActionSheet/ActionSheet'
import { OPEN_SAVE_AD_MODAL_STORAGE_KEY } from '@wh/common/chapter/lib/localStorage'
import { storageAvailable } from '@wh/common/chapter/lib/storageAvailable'
import { isUserLoggedIn, useProfileData } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { toast } from '@wh-components/core/Toast/Toast'
import { ApiErrorAlert } from '@wh/common/chapter/components/Alerts/ApiErrorAlert'

export interface SaveAdButtonProps {
    adId: string
    adTitle?: string
    isSaved: boolean
    setIsSaved: (isSaved: boolean) => void
    onClickTaggingAction?: () => Promise<void>
    onSavedTaggingAction?: () => Promise<void>
    onUnsavedTaggingAction?: () => Promise<void>
    children: JSX.Element
}

export const SaveAdButton: FunctionComponent<SaveAdButtonProps> = ({
    adId,
    adTitle,
    isSaved,
    setIsSaved,
    onClickTaggingAction,
    onSavedTaggingAction,
    onUnsavedTaggingAction,
    children,
}) => {
    const [profileData] = useProfileData()
    const [open, setOpen] = useState(false)
    const [list, setList] = useState<SavedAdsType[]>([])

    const onSaveSuccess = useCallback(() => {
        onSavedTaggingAction?.()
        setIsSaved(true)
    }, [onSavedTaggingAction, setIsSaved])

    const onUnsaveSuccess = useCallback(() => {
        onUnsavedTaggingAction?.()
        setIsSaved(false)
    }, [onUnsavedTaggingAction, setIsSaved])

    const handleOnClick = useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            try {
                const onClickTaggingActionPromise = onClickTaggingAction?.() || Promise.resolve()
                if (isUserLoggedIn(profileData)) {
                    if (!isSaved) {
                        if (list.length === 0) {
                            const savedAdsList = (await savedAdsApiClient.getSavedAdsList(profileData.loginId)).advertFolders
                            setList(savedAdsList)
                            if (savedAdsList.length === 1) {
                                await savedAdsApiClient.saveSavedAdsItem(profileData.loginId, savedAdsList[0].id, +adId)
                                onSaveSuccess()
                            } else {
                                setOpen(!open)
                            }
                        } else if (list.length === 1) {
                            await savedAdsApiClient.saveSavedAdsItem(profileData.loginId, list[0].id, +adId)
                            onSaveSuccess()
                        } else {
                            setOpen(!open)
                        }
                    } else {
                        await savedAdsApiClient.deleteSavedAdsItemWithoutFolderId(profileData.loginId, +adId)
                        onUnsaveSuccess()
                    }
                } else {
                    if (storageAvailable('localStorage')) {
                        localStorage.setItem(OPEN_SAVE_AD_MODAL_STORAGE_KEY, JSON.stringify({ adId, title: adTitle }))
                    }
                    await onClickTaggingActionPromise
                    redirectToLoginPage()
                }
            } catch (error) {
                const apiError = wrapInApiErrorIfNecessary(error)
                toast(<ApiErrorAlert error={apiError.errorResponse} />, { type: 'error', autoClose: false })
            }
        },
        [onClickTaggingAction, profileData, isSaved, list, adId, onSaveSuccess, open, onUnsaveSuccess, adTitle],
    )

    const handleItemOnClick = useCallback(
        async (savedAdId: number) => {
            try {
                if (isUserLoggedIn(profileData)) {
                    if (!isSaved) {
                        await savedAdsApiClient.saveSavedAdsItem(profileData.loginId, savedAdId, +adId)
                        onSaveSuccess()
                    }
                } else {
                    redirectToLoginPage()
                }
            } catch (error) {
                const apiError = wrapInApiErrorIfNecessary(error)
                toast(<ApiErrorAlert error={apiError.errorResponse} />, { type: 'error', autoClose: false })
            }
        },
        [profileData, isSaved, adId, onSaveSuccess],
    )

    return (
        <Fragment>
            <ActionSheet
                items={list.map((item) => ({
                    label: item.name,
                    onClick: () => handleItemOnClick(item.id),
                    testId: `save-ad-button-folder-${item.id}`,
                }))}
                isOpen={open}
                onRequestClose={(event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
                    event?.stopPropagation()
                    setOpen(false)
                }}
                dropdownPlacement="bottom-end"
                minWidth={150}
                maxWidth={400}
            >
                {React.cloneElement(children, { onClick: handleOnClick })}
            </ActionSheet>
        </Fragment>
    )
}
